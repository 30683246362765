<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div :class="componentClass">
    <template v-if="show">
      <span
        class="toast__msg"
        data-automation="toast-message__view"
      >
        <slot />
      </span>
      <button
        title="Close"
        class="toast__close"
        @click="onClose"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  computed: {
    componentClass() {
      const classList = ['toast'];
      if (this.show) {
        classList.push('toast--active');
      }
      if (this.type === 'error') {
        classList.push('toast--error');
      }
      return classList;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.toast {
  background: #E1F2E4;
  line-height: 1.2rem;
  opacity: 0;
  padding: 1.44rem;
  padding-right: 3.96rem;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transform: translateY(-1rem);
  transition: opacity 0.6s ease, transform 0.6s ease;
  width: 360px;
}

.toast--error {
  background: $color-alert-error;
  color: $color-white;
}

.toast--active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.toast__close {
  background: center center no-repeat url("../../images/close.svg");
  background-size: contain;
  border: none;
  cursor: pointer;
  height: 1.72rem;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1.08rem;
  width: 1.72rem;
}
</style>
