<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    role="status"
    class="loading-circle"
    aria-busy="true"
    aria-label="Loading..."
  >
    <div class="loading-circle__circle" />
  </div>
</template>

<script>
export default {
  name: 'MicroSpinner',
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.loading-circle {
  height: 28px;
  width: 28px;
}

.loading-circle__circle {
  animation: rotate-forever 0.7s linear 0.35s infinite;
  border: 4px solid transparent;
  border-color: $color-primary transparent;
  border-radius: 50%;
  box-sizing: border-box;
  height: 28px;
  opacity: 0.3;
  width: 28px;
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
