<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import RSButton from '@/elements/RSButton.vue';
import { contentListPath } from '@/utils/paths';
import { isEmbedded, locationHrefTo } from '@/utils/windowUtil';
import _unescape from 'lodash/unescape';
import markdownit from 'markdown-it';
import { onMounted, onUpdated, ref } from 'vue';

const props = defineProps({
  message: { type: String, default: '' },
  isActive: { type: Boolean, default: true },
  hasActiveSession: { type: Boolean, default: false },
  systemDisplayName: { type: String, default: 'Posit Connect' },
});
const defaultMessage = `# This content is locked.`;
const lockedMessageHtml = ref(null);

const renderMessage = () => {
  const md = markdownit({
    html: false, // never change this to `true` to prevent XSS vulnerability
    linkify: true,
  });

  lockedMessageHtml.value = md.render(
    _unescape(props.message) || defaultMessage
  );
};

// onMounted and onUpdated are both necessary here:
//   * When the locked message is rendered via the unauthorized app,
// .   `message` is available when the component is mounted.
//   * When the locked message is previewed in the dashboard app,
// .   `message` is only available in the onUpdated hook.
onMounted(renderMessage);
onUpdated(renderMessage);

const onNavigateToHome = () => {
  if (isEmbedded()) {
    locationHrefTo(contentListPath(), window.parent);
    return;
  }
  locationHrefTo(contentListPath());
};
</script>

<!-- 
  The eslint vue/no-v-html rule is disabled below, but it's ok. markdown-it produces safe HTML
  that can only be rendered using v-html. See above where `html: false` is set to prevent
  XSS vulnerabilities.
-->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="['locked-message-container', { 'is-active': isActive }]">
    <div
      :class="['locked-message', { 'is-active': isActive }]"
      data-automation="locked-message"
      v-html="lockedMessageHtml"
    />

    <RSButton
      v-if="isActive && hasActiveSession"
      type="primary"
      :label="`${systemDisplayName} Home`"
      class="dashboard-button"
      data-automation="locked-message-dashboard-button"
      @click="onNavigateToHome"
    />
  </div>
</template>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins.scss';

.locked-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dashboard-button {
    margin-bottom: 2rem;
  }

  .locked-message {
    @include normalize-css;

    margin: 2rem;
    max-width: 40rem;

    &.is-active {
      background-color: $color-light-blue;
      border-radius: 5px;
      height: auto;
      padding: 2rem;
    }

    a {
      color: $color-primary-dark;
      text-decoration: underline;
    }
  }
}
</style>
