<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Overview:
  When embedded in an iframe, login occurs in a popup window. When the auth provider is
  external, we force redirection via the dashboard. After successful login, the dashboard
  sends a message back here and we reload to the target content.
-->

<template>
  <div class="login-required">
    <p
      class="unauthorized-msg"
      data-automation="unauthenticated-login-msg"
    >
      Log in to see this content.
    </p>
    <div
      v-if="handlesLogin"
    >
      <a
        class="rs-button primary"
        :href="loginURL"
        target="_top"
        data-automation="unauthenticated-signin-link"
      >
        <span>
          Log In
        </span>
      </a>
    </div>
    <p
      v-if="handlesLogin"
      class="unauthorized-msg unauthorized-msg--small"
    >
      Don't have an account?
      <a
        v-if="selfRegister"
        class="link register"
        :href="registerPath"
        data-automation="unauthenticated-register-link"
        @click="onSelfRegister"
        @keypress.enter="onSelfRegister"
      >Sign Up</a>
      <span v-else>
        Contact your admin.
      </span>
    </p>
  </div>
</template>

<script>
import {
  embeddedInConnect,
  embeddedRedirectUrl,
  isEmbedded,
  sendParentFrameToRegister,
} from '@/utils/windowUtil';

export default {
  name: 'LoginRequired',
  props: {
    loginPath: {
      type: String,
      required: true,
    },
    registerPath: {
      type: String,
      required: true,
    },
    handlesLogin: {
      type: Boolean,
      required: true,
    },
    selfRegister: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      loginURL: '',
      browserRestrictions: false,
      loggedInPopup: false,
      channel: null,
      popup: null,
    };
  },
  computed: {
    embeddedOutsideConnect() {
      return isEmbedded() && !embeddedInConnect();
    },
  },
  created() {
    this.buildLoginHref();
  },
  methods: {
    buildLoginHref() {
      /**
       * Method responsible to obtain the proper login URL (depending on iframe prescence)
       */
      const loginURL = this.loginPath;
      let redirectURL = embeddedRedirectUrl();
      if (this.embeddedOutsideConnect && window.location.search) {
        const frameUrl = new URLSearchParams(window.location.search).get('framesource');
        if (frameUrl) { redirectURL = frameUrl; };
      }
      this.loginURL = `${loginURL}?url=${encodeURIComponent(redirectURL)}`;
    },
    onSelfRegister(ev) {
      // On register click, move parent frame to Connect's register page
      if (embeddedInConnect()) {
        ev.preventDefault();
        sendParentFrameToRegister();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login-required {
  text-align: center;

  .unauthorized-msg {
    font-size: 1.2rem;
    line-height: 1.44rem;
    margin: 1.44rem 0;

    &__small,
    &--small {
      font-size: 1rem;
    }
  }
}
</style>
