<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div :class="['unauthorized-view', { 'locked-message': showLockedMessage }]">
    <main v-if="initialized">
      <LoginRequired
        v-if="loginRequired"
        :login-path="loginPath"
        :register-path="registerPath"
        :handles-login="handlesLogin"
        :self-register="selfRegistration"
      />
      <RequestAccess
        v-else-if="showRequestAccess"
        :guid="guid"
        :user-guid="userGUID"
        :user-role="userRole"
        :email-enabled="connectCanSendEmail"
        :broadcast-channel="broadcastChannel"
        :is-locked="isLocked"
        @request-sent="onRequestDone"
        @request-error="onRequestError"
      />
      <div v-else-if="showLockedMessage">
        <LockedMessage
          v-if="isLocked"
          :message="lockedMessage"
          :has-active-session="hasActiveSession"
          :system-display-name="systemDisplayName"
        />
      </div>
      <h1
        v-else-if="showNotFound"
        data-automation="unauthorized-not-found"
      >
        404
      </h1>
      <div
        v-else
        class="center"
      >
        <p class="unauthorized-msg">
          It looks like this content hasn't been shared with you.
        </p>
        <a
          class="rs-button primary"
          target="_top"
          :href="contentListURL"
        >
          Go to Content Listing
        </a>
      </div>
    </main>
    <MessageBox
      v-if="showEmailWarning"
      class="unauthorized-email-disabled-msg"
      use-icon
      alert
    >
      You can't request access to this content because {{ systemDisplayName }}'s
      email server hasn't been set up. Contact your admin to set up the email server.
    </MessageBox>
    <Toast
      :show="showToast"
      :type="toastType"
      @close="closeToast"
    >
      {{ toastMessage }}
    </Toast>
  </div>
</template>

<script>
import UserRoles from '@/api/dto/userRole';
import { safeAPIErrorCode } from '@/api/error';
import ApiErrors from '@/api/errorCodes';
import { getServerSettings } from '@/api/serverSettings';
import { getCurrentUser } from '@/api/users';
import MessageBox from '@/components/MessageBox';
import Toast from '@/components/Toast';
import {
  fullDashboardPath,
  loginPath,
  registerPath,
} from '@/utils/paths';
import {
  isEmbedded,
  locationHrefTo,
} from '@/utils/windowUtil';
import LockedMessage from '@/views/locked-content/LockedMessage.vue';
import LoginRequired from './LoginRequired';
import RequestAccess from './RequestAccess';

export const ViewTypes = {
  Locked: 'locked',
  Unauthorized: 'unauthorized',
};

export default {
  name: 'UnauthorizedView',
  components: {
    LockedMessage,
    LoginRequired,
    MessageBox,
    RequestAccess,
    Toast,
  },
  props: {
    guid: {
      type: String,
      default: '',
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    lockedMessage: {
      type: String,
      default: '',
    },
    systemDisplayName: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialized: false,
      userGUID: '',
      userRole: null,
      hasActiveSession: false,
      showToast: false,
      toastType: '',
      errorOnRequest: false,
      serverAddress: '',
      handlesLogin: false,
      selfRegistration: false,
      permissionRequestEnabled: false,
      viewersCanRequestPrivileges: true,
      connectCanSendEmail: false,
      broadcastChannel: null,
      loginPath: loginPath(),
      registerPath: registerPath(),
      contentListURL: fullDashboardPath,
      dashboardPath: '',
    };
  },
  computed: {
    loginRequired() {
      return !this.hasActiveSession && !this.guid;
    },
    showNotFound() {
      return this.hasActiveSession && !this.guid;
    },
    allowPrivilegeRequest() {
      return this.viewersCanRequestPrivileges || this.userRole !== UserRoles.Viewer;
    },
    showLockedMessage() { 
      return this.view === ViewTypes.Locked;
    },
    showRequestAccess() {
      return (
        !this.showNotFound
        && this.view === ViewTypes.Unauthorized
        && this.hasActiveSession
        && this.permissionRequestEnabled
        && this.allowPrivilegeRequest
      );
    },
    showEmailWarning() {
      return (
        this.initialized
        && !this.connectCanSendEmail
        && this.userGUID
        && this.userRole !== UserRoles.Admin
        && this.allowPrivilegeRequest
      );
    },
    toastMessage() {
      if (this.errorOnRequest) {
        return 'Something went wrong. Try again later.';
      }
      if (this.userRole === UserRoles.Admin) {
        return 'Access granted. This is an auditable action.';
      }
      return 'Your access request has been sent.';
    },
  },
  async created() {
    // Create the broadcast channel to propagate login
    // and/or notify dashboard about admin self-grant access
    if ('BroadcastChannel' in window && !this.broadcastChannel) {
      try {
        this.broadcastChannel = new BroadcastChannel('rstudio-connect');
        // eslint-disable-next-line no-unused-vars
      } catch (e) {
        // storage access policy likely prevented the channel from being created
        // https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Privacy/Storage_access_policy#What_does_the_storage_access_policy_block
        return;
      }
    }
    await this.init();
  },
  methods: {
    async init() {
      try {
        if (this.isLocked) {
          this.initializeUser(await getCurrentUser());
          if (this.hasActiveSession) {
            this.initializeServer(await getServerSettings());
          }
          this.initialized = true;
          return;
        }

        this.initializeServer(await getServerSettings());
        this.initializeUser(await getCurrentUser());
      } catch (err) {
        if (err.response?.status === 401) {
          this.hasActiveSession = false;
        }
      }

      const willRedirect = this.goToLoginPageIfNeeded();
      /**
         * We do not mark component as initialized to not render anything on the screen
         * since a redirect will take place. This to prevent flashy-intermittent components.
         */
      if (!willRedirect) {
        this.initialized = true;
      }
    },
    initializeUser(user) {
      this.userGUID = user.guid;
      this.userRole = user.userRole;
      this.hasActiveSession = true;
    },
    initializeServer(serverSettings) {
      this.connectCanSendEmail = serverSettings.mailConfigured;
      this.handlesLogin = serverSettings.authentication.handlesLogin;
      this.selfRegistration = serverSettings.selfRegistration;
      this.permissionRequestEnabled = serverSettings.permissionRequest;
      this.viewersCanRequestPrivileges = serverSettings.viewersCanRequestPrivileges;
      this.dashboardPath = serverSettings.dashboardPath;
    },
    goToLoginPageIfNeeded() {
      /**
       * When we get 401 and if content is not embedded, go directly to login page.
       * We don't want to redirect to login if content is embedded due to browser policies,
       * in which a direct intent of login is required, meaning we need the user to click a button.
       *
       * Redirecting to the login page helps to pick up any existing SSO session (if any),
       * in which SSO kicks-in as expected by our users.
       * 
       * If content GUID is present at this point, either
       * - the user is logged in
       * - OR this is a publicly available content item.
       */

      // Do not redirect if is locked content and has guid (is publicly available)
      if (this.guid && this.isLocked) {
        return false;
      }

      const shouldRedirect = !this.hasActiveSession && !isEmbedded();
      if (shouldRedirect) {
        // Updating location.href directly since $router and $route are not available here.
        // The Unauthorized app does not need Vue Router at all.
        locationHrefTo(loginPath({ redirect: window.location.href }));
      }
      return shouldRedirect;
    },
    onRequestDone() {
      this.errorOnRequest = false;
      this.toastType = 'info';
      this.showToast = true;
    },
    onRequestError(err) {
      const errorCode = safeAPIErrorCode(err);
      const { MissingServerSenderEmail, EmailFailure, MissingServerAddress } = ApiErrors;
      const isEmailError = [
        EmailFailure,
        MissingServerAddress,
        MissingServerSenderEmail,
      ].includes(errorCode);
      if (isEmailError) {
        this.connectCanSendEmail = false;
        return;
      }
      this.errorOnRequest = true;
      this.toastType = 'error';
      this.showToast = true;
    },
    closeToast() {
      this.showToast = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#unauthorized-app,
.unauthorized-view {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &.locked-message {
    justify-content: flex-start;
  }
}

.unauthorized-email-disabled-msg {
  margin-top: 3rem;
  max-width: 600px;
}
</style>
