<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Overview:
  When user doesn't have access to content, the user can request access.
-->

<template>
  <div class="content-request-access">
    <MessageBox
      v-if="isLocked"
      data-automation="generic-locked-content-message"
    >
      This content has been locked and cannot be viewed.<br>
      You may {{ isAdmin ? 'gain' : 'request' }} access to see additional details about this content,
      but the content will not be viewable.
    </MessageBox>

    <p
      v-if="!isAdmin"
      class="unauthorized-msg"
    >
      It looks like this content hasn't been shared with you.
    </p>
    <div v-else>
      <p
        class="unauthorized-msg"
      >
        This content hasn't been shared with you.
      </p>
      <MessageBox
        data-automation="admin-overrides-alert"
        alert
      >
        Administrative overrides of content permissions will be captured in the audit logs.
      </MessageBox>
    </div>
    <p
      v-if="hasBeenRequested"
      class="last-requested-time"
    >
      Last Requested Time: {{ timestamp }}
    </p>
    <div
      v-if="initialized"
      class="content-request-access__controls"
    >
      <RSButton
        ref="requestButton"
        :label="requestBtnLabel"
        :class="requestBtnClass"
        :disabled="disableReqBtn"
        data-automation="request-access-btn"
        @click="toggleOptions"
        @keydown.esc="hideMenu({ keepFocus: true })"
      >
        <figure
          v-if="showSubmittedIcon"
          class="request-access-btn__icon request-access-btn__icon--waiting"
        />
        {{ requestBtnLabel }}
        <figure
          v-if="!requestSubmitted"
          class="request-access-btn__icon request-access-btn__icon--caret"
        />
      </RSButton>
      <MicroSpinner v-if="submitting" />
      <div
        v-if="useAccessOptions && showAccessOptions"
        :class="optionsClass"
        data-automation="access-options"
        role="menu"
        tabindex="-1"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.tab="hideMenu"
        @keydown.esc="hideMenu({ keepFocus: true })"
      >
        <button
          v-for="option in accessOptions"
          :ref="option.name"
          :key="option.name"
          tabindex="-1"
          :data-automation="`access-option__${ option.name }`"
          :class="`access-option access-option--${ option.name }`"
          @click="option.click"
        >
          <figure :class="`access-option__icon access-option__icon--${ option.name }`" />
          <strong>
            {{ option.requestLabel }}
          </strong>
          <br>
          <span class="access-option__desc">
            {{ option.requestDescription }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { addUser } from '@/api/app';
import AppRoles from '@/api/dto/appRole';
import UserRoles from '@/api/dto/userRole';
import { getPendingRequestAccess, requestContentPermissions } from '@/api/permissions';
import MessageBox from '@/components/MessageBox';
import MicroSpinner from '@/components/MicroSpinner';
import RSButton from '@/elements/RSButton.vue';
import { BroadcastMessageType } from '@/utils/iframeBus';
import { utcToLocalTimezone } from '@/utils/timezone';
import { postMsgToParent, reloadWindow } from '@/utils/windowUtil';

export default {
  name: 'RequestAccess',
  components: {
    RSButton,
    MessageBox,
    MicroSpinner,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
    isLocked: {
      type: Boolean,
      required: true,
    },
    userGuid: {
      type: String,
      required: true,
    },
    userRole: {
      type: Number,
      required: true,
    },
    emailEnabled: {
      type: Boolean,
      required: true,
    },
    /* eslint-disable vue/require-prop-types */
    broadcastChannel: {
      // Not setting type as BroadcastChannel constructor not supported in IE
      default: null,
    },
    /* eslint-enable vue/require-prop-types */
  },
  emits: ['requestError', 'requestSent'],
  data() {
    return {
      initialized: false,
      submitting: false,
      requestSubmitted: false,
      showAccessOptions: false,
      timestamp: '',
      currentItem: 0,
    };
  },
  computed: {
    hasBeenRequested() {
      return this.timestamp !== '';
    },
    isViewer() {
      return this.userRole === UserRoles.Viewer;
    },
    isAdmin() {
      return this.userRole === UserRoles.Admin;
    },
    showSubmittedIcon() {
      return this.requestSubmitted && !this.isAdmin;
    },
    requestBtnLabel() {
      if (this.requestSubmitted) {
        return 'Re-request access';
      }
      return this.isAdmin
        ? 'Gain Access'
        : 'Request Access';
    },
    useAccessOptions() {
      return this.emailEnabled || this.isAdmin;
    },
    disableReqBtn() {
      return this.submitting || !this.useAccessOptions;
    },
    requestBtnClass() {
      const classList = ['request-access-btn'];
      if (this.showAccessOptions && !this.requestSubmitted) {
        classList.push('request-access-btn--active');
      }
      return classList;
    },
    accessOptions() {
      return [
        {
          name: 'edit',
          click: () => this.requestEditAccess(),
          text: 'requestEdit',
          requestLabel: 'Collaborator',
          requestDescription: 'Edit, manage, or share this content.',
          available: !this.isViewer,
        },
        {
          name: 'view',
          click: () => this.requestViewAccess(),
          requestLabel: 'Viewer',
          requestDescription: 'View or print this content.',
          available: true,
        }
      ].filter(item => item.available);
    },
    optionsClass() {
      const classList = ['request-access-options'];
      if (this.showAccessOptions && !this.disableReqBtn) {
        classList.push('request-access-options--active');
      }
      return classList;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getPending();
    },
    getPending(){
      return getPendingRequestAccess(this.guid)
        .then(response => {
          this.timestamp = this.formattedTimestamp(response.lastRequestedTime);
          this.requestSubmitted = true;
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            this.requestSubmitted = false;
          }
        })
        .finally(() => {
          this.initialized = true;
        });
    },
    toggleOptions() {
      this.showAccessOptions = !this.showAccessOptions;
      if (this.showAccessOptions) {
        this.currentItem = 0;
        this.focusItem(this.currentItem);
      }
    },
    focusItem(index) {
      const item = this.accessOptions[index].name;
      this.$nextTick().then(() => this.$refs[item][0].focus());
    },
    hideMenu({ keepFocus = false }) {
      this.showAccessOptions = false;
      if (keepFocus) {
        this.$nextTick().then(() => this.$refs.requestButton.focusElement());
      }
    },
    onArrowUp() {
      const currentItem = this.currentItem - 1;
      this.currentItem = currentItem < 0 ? this.accessOptions.length - 1 : currentItem;
      this.focusItem(this.currentItem);
    },
    onArrowDown() {
      const currentItem = this.currentItem + 1;
      this.currentItem = currentItem > this.accessOptions.length - 1 ? 0 : currentItem;
      this.focusItem(this.currentItem);
    },
    formattedTimestamp(entry) {
      if (!entry) {
        return '';
      }
      return utcToLocalTimezone(entry, 'MM/DD/YYYY HH:mm:ss');
    },
    requestEditAccess() {
      this.requestAccess(AppRoles.Owner);
    },
    requestViewAccess() {
      this.requestAccess(AppRoles.Viewer);
    },
    requestAccess(appRole) {
      const permissionType = AppRoles.stringOf(appRole);
      if (this.isAdmin) {
        this.onAdminGetAccess(permissionType);
        return;
      }
      this.submitting = true;
      requestContentPermissions({
        guid: this.guid,
        permissionType,
      })
        .then(() => {
          this.requestSubmitted = true;
          this.$emit('requestSent');
          return this.getPending();
        })
        .catch(err => {
          this.$emit('requestError', err);
        })
        .finally(() => {
          this.submitting = false;
          this.toggleOptions();
        });
    },
    onAdminGetAccess(type) {
      const loadingDebounce = setTimeout(() => {
        this.submitting = true;
      }, 300);
      addUser(this.guid, this.userGuid, type)
        .then(this.onAdminAccessSuccess)
        .catch(err => {
          clearTimeout(loadingDebounce);
          this.$emit('requestError', err);
          this.submitting = false;
        });
    },
    onAdminAccessSuccess() {
      const msg = {
        type: BroadcastMessageType.AdminContentAccess,
      };
      if (this.broadcastChannel) {
        this.broadcastChannel.postMessage(msg);
      }
      postMsgToParent(msg);
      reloadWindow(msg);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "Styles/shared/_colors";

.content-request-access {
  background-color: $color-light-grey;
  border-radius: 3px;
  max-width: 50vw;
  padding: 2rem 2rem 2rem;
  position: relative;

  .rsc-message-box {
    margin-bottom: 3rem;
  }

  &__controls {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }

  .unauthorized-msg {
    font-size: 1.2rem;
    line-height: 1.44rem;
    margin: 1.44rem 0;

    &__small,
    &--small {
      font-size: 1rem;
    }
  }

  .last-requested-time {
    font-size: .9rem;
    line-height: 1rem;
    margin: 1.44rem 0;

    &__small,
    &--small {
      font-size: 1rem;
    }
  }

  .request-access-btn {
    line-height: 1.2rem;
    margin-bottom: 1rem;
    padding-left: 1.44rem;
    padding-right: 1.44rem;

    &__icon {
      display: inline-block;
      transition: transform 0.2s ease;

      &--waiting {
        background: center center no-repeat url(Images/waiting-request.svg);
        background-size: auto 100%;
        margin-right: 6px;
        padding: 10px;
        vertical-align: bottom;
      }

      &--caret {
        background: center center no-repeat url(Images/caret-down.svg);
        background-size: contain;
        margin-left: 6px;
        padding: 6px;
      }
    }

    &--active {
      .request-access-btn__icon--caret {
        transform: rotate(180deg);
      }
    }
  }

  .request-access-options {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: translateY(-.5rem);
    transition: all 0.2s ease;
    width: 310px;

    &--active {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(-2rem);
    }
  }

  .access-option {
    background: white;
    color: #444;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.44rem;
    padding: 1rem 1rem 1rem 3.2rem;
    position: relative;
    text-align: left;
    transition: background 0.2s ease;
    width: 310px;

    &+& {
      border-top: 1px solid #E3E3E3;
    }

    &__icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 1rem;
      left: 1rem;
      margin: 0;
      position: absolute;
      top: 1.1rem;
      width: 1.44rem;
    }

    &__desc {
      color: $color-dark-grey;
      font-size: 0.9rem;
    }

    &:hover {
      background-color: $color-posit-teal;
      color: $color-white;

      .access-option__desc {
        color: $color-white;
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
      background-color: $color-posit-teal;
      color: $color-white;

      .access-option__desc {
        color: $color-white;
      }
    }
  }
}
</style>

