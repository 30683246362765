// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Unauthorized mini-app served only for the unauthorized view (404|403)
 * See vite.config.unauthorized.js for entry and output.
 */

import Unauthorized from '@/views/unauthorized/Unauthorized.vue';
import 'core-js';
import { createApp, h } from 'vue';
import '../css/connect.scss';

// Initialize Axios
import '@/api/axiosSetup';

const appElement = document.querySelector('#unauthorized-app');
const viewProps = appElement.getAttributeNames().reduce((acc, attrName) => {
  if (!attrName.startsWith('data-')) {return acc;}

  const propName = attrName.replace('data-', '');
  if (propName.startsWith('is-')) {
    acc[propName] = appElement.getAttribute(attrName) === 'true';
    return acc;
  }
  acc[propName] = appElement.getAttribute(attrName);
  return acc;
}, {});

const app = createApp({
  name: 'Unauthorized',
  beforeMount() {
    this.viewProps = viewProps;
  },
  render() {
    return h(Unauthorized, viewProps);
  },
});
app.mount('#unauthorized-app');
